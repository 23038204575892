import React from "react";
import { useTranslation } from "react-i18next";

import { Subtitle } from "../components/Text";
import Link from "../components/icons/Link";
import { SocialShareLinks } from "./SocialShare.jsx";

export function About() {
  const { t } = useTranslation("about");
  return (
    <div className="flex flex-col py-4 sm:px-16 items-center justify-center max-sm:w-343px max-w-screen-xxl mx-auto sm:w-870px pb-40">
      <div className="text-center text-8 sm:text-12 font-700 text-lastHaven-black pb-4">
        {t("title")}
      </div>
      <Subtitle className="text-left" text={t("subtitle")} />

      <Section
        title={t("concept.title")}
        links={[
          { href: t("concept.link-1.href"), text: t("concept.link-1.text") },
          { href: t("concept.link-2.href"), text: t("concept.link-2.text") },
        ]}
      />
      <Section
        title={t("principal-investigators.title")}
        links={[
          {
            href: t("principal-investigators.link-1.href"),
            text: t("principal-investigators.link-1.text"),
          },
          {
            href: t("principal-investigators.link-2.href"),
            text: t("principal-investigators.link-2.text"),
          },
        ]}
      />
      <Section
        title={t("lead-researcher.title")}
        links={[
          {
            href: t("lead-researcher.link-1.href"),
            text: t("lead-researcher.link-1.text"),
          },
        ]}
      />
      <Section
        title={t("team.title")}
        links={[
          { href: t("team.link-1.href"), text: t("team.link-1.text") },
          { href: t("team.link-2.href"), text: t("team.link-2.text") },
          { href: t("team.link-3.href"), text: t("team.link-3.text") },
          { href: t("team.link-4.href"), text: t("team.link-4.text") },
          { href: t("team.link-5.href"), text: t("team.link-5.text") },
          { href: t("team.link-6.href"), text: t("team.link-6.text") },
          { href: t("team.link-7.href"), text: t("team.link-7.text") },
        ]}
      />
      <SubSection
        title={t("with-contributions-from.title")}
        links={[
          {
            href: t("with-contributions-from.link-1.href"),
            text: t("with-contributions-from.link-1.text"),
          },
          {
            href: t("with-contributions-from.link-2.href"),
            text: t("with-contributions-from.link-2.text"),
          },
        ]}
      />
      <Section
        title={t("project-management.title")}
        links={[
          {
            href: t("project-management.link-1.href"),
            text: t("project-management.link-1.text"),
          },
        ]}
      />
      <SectionBreak text={t("acknowledgments")} />
      <div
        className="w-full"
        dangerouslySetInnerHTML={{ __html: t("acknowledgments-text") }}
      />
      <div className="py-4">
        <div className="text-center pb-2 font-700 text-5">
          {t("social-heading")}
        </div>
        <div className="text-center pb-2 font-400 text-lastHaven-gray-600">
          {t("social-subheading")}
        </div>
        <SocialShareLinks />
      </div>
    </div>
  );
}

const SectionBreak = ({ text }) => (
  <div className="w-full flex items-center py-4">
    <hr className="flex-grow border-gray-300 min-w-10" />
    <span className="text-lastHaven-black text-6 font-700 px-4 max-w-395px text-center">
      {text}
    </span>
    <hr className="flex-grow border-gray-300 min-w-10" />
  </div>
);

const Section = ({ title, links }) => (
  <div className="w-full">
    <SectionBreak text={title} />
    <LinkList links={links} />
  </div>
);

const SubSection = ({ title, links }) => (
  <div className="w-full">
    <div className="w-full flex items-center py-4">
      <div className="flex-grow" />
      <span className="text-lastHaven-black text-6 font-700 px-4 max-w-340px text-center">
        {title}
      </span>
      <div className="flex-grow" />
    </div>
    <LinkList links={links} />
  </div>
);

const LinkList = ({ links }) => (
  <div className="flex max-sm:flex-col sm:flex-wrap items-center justify-center w-full">
    {links.map((link, ii) => (
      <div
        key={ii}
        className="flex sm:justify-center align-middle  text-18px min-w-175px max-w-220px h-8"
      >
        {link.href ? (
          <a
            href={link.href}
            target="_blank"
            rel="noreferrer"
            className="text-lastHaven-primary-main"
          >
            <div className="flex items-center h-8 pb-2">
              <Link className="mr-2  text-lastHaven-primary-main" />
              {link.text}
            </div>
          </a>
        ) : (
          link.text
        )}
      </div>
    ))}
  </div>
);
