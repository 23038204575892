import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "../components/Button";
import { useModalStore } from "../hooks/useModalStore";
import { useStore } from "../hooks/useStore";

export function Landing() {
  const { t } = useTranslation("landing");
  const navigate = useNavigate();
  const openInstructions = useModalStore(
    (state) => state.openInstructionsModal
  );
  const instructionsViewed = useStore((state) => state.instructionsViewed);

  const buttonClickHandler = () => {
    if (instructionsViewed) {
      navigate("/play");
    } else {
      openInstructions();
    }
  };

  return (
    <div className="flex flex-col xl:pb-104px w-375px max-sm:px-4 max-xl:px-16 sm:w-full xl:min-w-1312px xl:w-1312px mx-auto">
      <div className="flex max-xl:flex-col max-xl:justify-start xl:justify-between items-center xl:py-8 font-sans xl:min-h-451px w-full">
        <div className={`xl:hidden w-screen h-376px overflow-hidden relative`}>
          <img
            src="images/landing-hummingbird.webp"
            alt={t("image-3-alt")}
            className="h-181px w-auto absolute z-1 top-0 left-1/2 transform -translate-x-1/2"
          />
          <img
            src="images/landing.webp"
            alt={t("image-alt")}
            className="h-349px w-auto object-cover absolute z-0 bottom-0 left-1/2 transform -translate-x-1/2"
          />
        </div>
        <div
          className={`flex flex-col justify-center w-343px sm:w-full xl:w-568px xl:h-451px`}
        >
          <div className="text-left text-12 leading-66px font-700 text-lastHaven-black pt-4">
            {t("title")}
          </div>
          <div className="text-left sm:text-6 sm:leading-8 text-lastHaven-black pt-4">
            {t("description")}
          </div>
          <div className="flex pt-6 max-xl:pb-6">
            <Button
              className="h-57px w-353px"
              onClick={buttonClickHandler}
              text={t("primary-action-label")}
            />
          </div>
        </div>
        <div
          className={`max-xl:hidden w-744px xl:h-408px overflow-hidden flex justify-center relative`}
        >
          <img
            src="images/landing-hummingbird.webp"
            alt={t("image-3-alt")}
            className="h-232px w-auto absolute z-1 top-15 -left-3"
          />
          <img
            src="images/landing.webp"
            alt={t("image-alt")}
            className="h-408px w-648px absolute right-0 z-0"
          />
        </div>
      </div>
      <div className="flex max-xl:flex-col max-xl:pt-4 xl:justify-between items-center w-full">
        <div className="sm:h-351px xl:w-624px rounded-2 overflow-hidden">
          <img
            src="images/landing-video.webp"
            alt={t("image2-alt")}
            className="sm:max-h-351px"
          />
        </div>
        <div className="flex flex-col xl:w-636px max-xl:pt-8 max-xl:pb-24">
          <div className="w-full">
            <div className="font-700 text-6 leading-8">{t("header-1")}</div>
            <div className="w-full h-2" />
            <div className="leading-6">{t("paragraph-1")}</div>
          </div>
          <div className="w-full h-8 xl:h-6" />
          <div className="w-full">
            <div className="font-700 text-6 leading-8">{t("header-2")}</div>
            <div className="w-full h-2" />
            <div className="leading-6">{t("paragraph-2")}</div>
          </div>
        </div>
      </div>
      <div className="flex max-xl:flex-col justify-center items-center w-full max-xl:pb-15 xl:py-30">
        <img
          className="h-36 w-auto xl:px-10 max-xl:py-5"
          src="images/collaborators/Oxford.png"
          alt="University of Oxford"
        />
        <img
          className="h-36 w-auto xl:px-10 max-xl:py-5"
          src="images/collaborators/NUS.png"
          alt="National University of Singapore"
        />
        <img
          className="h-36 w-auto xl:px-10 max-xl:py-5"
          src="images/collaborators/Exeter.png"
          alt="University of Exeter"
        />
      </div>
    </div>
  );
}
