import React from "react";
import { useTranslation } from "react-i18next";

import {
  TitleLarge,
  Subtitle,
  Paragraph,
  BoldParagraph,
} from "../components/Text";
import { NUM_FAQS } from "@last-haven/shared/constants";

export function FAQ() {
  const { t } = useTranslation("faq");
  const numQuestions = NUM_FAQS;
  const hasSubtitle = t("subtitle") !== "subtitle";
  return (
    <div className="flex flex-col items-center p-4 sm:px-16 sm:mt-10">
      <div className="w-85.75 sm:w-328 sm:px-55.25 items-start">
        <TitleLarge text={t("title")} />
        <div className="h-4" />
        {hasSubtitle && (
          <Subtitle
            className="text-left text-4 text-lastHaven-black"
            text={t("subtitle")}
          />
        )}
        <ol>
          {Array(numQuestions)
            .fill(0)
            .map((question, ii) => (
              <li key={ii} className="pt-4">
                <BoldParagraph
                  className="text-5"
                  text={`${ii + 1}. ${t(`question-${ii + 1}`)}`}
                />
                <div className="sm:h-2" />
                <Paragraph text={t(`answer-${ii + 1}`)} />
              </li>
            ))}
        </ol>
      </div>
    </div>
  );
}
