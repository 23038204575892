import React from "react";
import { useTranslation } from "react-i18next";

import { speciesMap, buildingsMap } from "@last-haven/shared/constants";

const forTranslation = (s) => s.toLowerCase().replace(/ /g, "-");
const Row = ({ title, items }) => {
  const { t } = useTranslation("card");
  return (
    <div>
      <div className="text-8 font-600 py-4">{title}</div>
      <div className="flex flex-col gap-2">
        {items.map(
          ({
            image,
            label,
            priority,
            id,
            economicImpact,
            ecologicalImpact,
            class: classType,
            charisma,
            ecologicalImportance,
          }) => (
            <div
              key={label}
              className={`flex flex-row items-center min-w-128px min-h-84px mx-2 ${
                priority === "primary"
                  ? "bg-lastHaven-gray-300"
                  : priority === "secondary"
                  ? "bg-lastHaven-gray-100"
                  : ""
              }`}
            >
              <img
                className="h-80px w-auto"
                src={image}
                alt={t(
                  `${forTranslation(title)}.${forTranslation(label)}.label`
                )}
              />
              <h2 className="px-4 w-196px break-words hyphens-auto">
                {t(`${forTranslation(title)}.${forTranslation(label)}.label`)}
              </h2>
              <h2 className="px-4 w-100px break-words hyphens-auto">
                id = {id}
              </h2>
              {ecologicalImpact && (
                <h2 className="px-4 w-250px break-words hyphens-auto">
                  ecologicalImpact = {ecologicalImpact}
                </h2>
              )}
              {economicImpact && (
                <h2 className="px-4 w-250px break-words hyphens-auto">
                  economicImpact = {economicImpact}
                </h2>
              )}
              {classType && (
                <h2 className="px-4 w-200px break-words hyphens-auto">
                  class = {classType}
                </h2>
              )}
              {charisma && (
                <h2 className="px-4 w-200px break-words hyphens-auto">
                  charisma = {charisma}
                </h2>
              )}
              {ecologicalImportance && (
                <h2 className="px-4 w-300px break-words hyphens-auto">
                  ecologicalImportance = {ecologicalImportance}
                </h2>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export function Attributes() {
  return (
    <div className="flex flex-col justify-between min-h-120">
      <div className="flex w-50% justify-center items-center">
        <span>primary = </span>
        <div className="ml-2 w-8 h-8 bg-lastHaven-gray-300" />
        <span className="ml-4">secondary = </span>
        <div className="ml-2 w-8 h-8 bg-lastHaven-gray-100" />
      </div>
      <Row title="Species" items={Object.values(speciesMap)} />
      <Row title="Buildings" items={Object.values(buildingsMap)} />
      <div className="h-8" />
    </div>
  );
}
