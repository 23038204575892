import React from "react";
import Popup from "reactjs-popup";

export function Modal({ open, onClose, className = "", style = {}, children }) {
  return (
    <Popup modal open={open} onClose={onClose}>
      {(onClose) => (
        <div
          className={`animate-fade-in-down animate-duration-200 flex flex-col justify-start sm:justify-center items-center  bg-lastHaven-white rounded-2 sm:rounded-4 max-sm:min-w-screen max-sm:h-[100svh] ${className}`}
          style={{ boxShadow: "0 0 0 100vmax rgba(0, 0, 0, 0.8)", ...style }}
          onClose={onClose}
        >
          <div className="max-h-72px h-72px w-full flex items-center justify-end z-10">
            <button
              className="flex justify-center items-center bg-lastHaven-white h-10 w-10 focus:(outline-none transform scale-[0.96]) hover:(transform scale-[0.96])"
              tabIndex="0"
              onClick={onClose}
            >
              <img
                src="images/modal-close.svg"
                alt="Close"
                className="w-8 h-8"
              />
            </button>
          </div>
          <div className="flex-grow max-sm:max-h-86svh">{children}</div>
        </div>
      )}
    </Popup>
  );
}
