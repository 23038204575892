import React, { useState, useCallback, useEffect } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { SurveyQuestion } from "../components/SurveyQuestion";
import { Title, Subtitle } from "../components/Text";
import { Button } from "../components/Button";
import { useStore } from "../hooks/useStore";

export function Survey() {
  const { nextPath } = useLoaderData();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [answers, setAnswers] = useState({});
  const { t } = useTranslation("survey");
  const surveyCompleted = useStore((state) => state.surveyCompleted);
  const completeSurvey = useStore((state) => state.completeSurvey);
  const surveyQuestions = useStore((state) => state.surveyQuestions);
  const navigate = useNavigate();

  const onNext = useCallback(() => navigate(nextPath), [navigate, nextPath]);
  useEffect(() => {
    if (surveyCompleted) {
      onNext();
    }
  }, [surveyCompleted, onNext]);

  const onSubmit = async () => {
    setSubmitDisabled(true);
    await completeSurvey({
      answers: Object.keys(answers).map((key) => ({
        index: key,
        ...answers[key],
      })),
    });
    setSubmitDisabled(false);
    onNext();
  };

  const answerHandler = ({
    index,
    displayIndex,
    questionId,
    question,
    answer,
  }) => {
    setAnswers((answers) => ({
      ...answers,
      [index]: {
        displayIndex,
        questionId,
        question,
        answer,
        created: Date.now(),
      },
    }));
  };
  return (
    <div className="font-sans flex flex-col sm:w-1090px max-sm:px-4 sm:m-auto">
      <div className="py-12">
        <div className="min-h-4" />
        <Title
          className="text-left sm:text-8 sm:leading-11 font-700"
          text={t("title")}
        />
        <Subtitle
          className="text-left text-4.5 leading-8"
          text={t("subtitle")}
        />
      </div>

      {surveyQuestions.map((questionNumber, displayIndex) => (
        <SurveyQuestion
          number={questionNumber}
          displayNumber={displayIndex + 1}
          key={questionNumber}
          t={t}
          onAnswer={answerHandler}
          answers={answers[questionNumber]?.answer ?? []}
        />
      ))}
      <div className="flex justify-center py-4">
        <Button
          secondary
          onClick={onNext}
          text={t("secondary-button-label")}
          className="w-50 max-sm:px-4"
        />
        <div className="w-4" />
        <Button
          onClick={onSubmit}
          text={t("primary-button-label")}
          disabled={submitDisabled}
          className="w-50 max-sm:px-4"
        />
      </div>
      <div className="h-12" />
    </div>
  );
}
