import { Classes } from "./constants"

const getChosen = (pair) => pair.filter((card) => card.chosen)?.[0];
// Helper functions to count specific attributes and chosen status
function countAttributes(userDilemmas, filterFunc, chosenType = 'species') {
  const filteredDilemmas = userDilemmas.filter(filterFunc);
  const total = filteredDilemmas.length;
  const matchedDilemmas = filteredDilemmas.filter(decisionPair => getChosen(decisionPair).hasOwnProperty(chosenType));
  return { total, chosen: matchedDilemmas.length };
}

function calculateSavedClasses(userDilemmas) {
  let classes = Object.values(Classes);
  let scores = {};
  let highestScoreClasses: Classes[] = [];
  let lowestScoreClasses: Classes[] = [];
  let highestScore = -1;
  let lowestScore = 2; // Since scores are between 0 and 1
  let highestCount = 0;
  let lowestCount = Infinity;

  classes.forEach(speciesClass => {
    const filterFunc = dilemma => dilemma.some(d => d.species?.class === speciesClass);
    const { total, chosen } = countAttributes(userDilemmas, filterFunc);
    let scoreC = total === 0 ? 0 : chosen / total;
    scores[speciesClass] = { score: scoreC, count: total };

    if (scoreC > highestScore || (scoreC === highestScore && total > highestCount)) {
      highestScore = scoreC;
      highestCount = total;
      highestScoreClasses = [speciesClass];
    } else if (scoreC === highestScore && total === highestCount) {
      highestScoreClasses.push(speciesClass);
    }

    if (scoreC < lowestScore || (scoreC === lowestScore && total < lowestCount)) {
      lowestScore = scoreC;
      lowestCount = total;
      lowestScoreClasses = [speciesClass];
    } else if (scoreC === lowestScore && total === lowestCount) {
      lowestScoreClasses.push(speciesClass);
    }
  });

  // Randomly select from tied classes
  let mostSavedClass = highestScoreClasses[Math.floor(Math.random() * highestScoreClasses.length)];
  let leastSavedClass = lowestScoreClasses[Math.floor(Math.random() * lowestScoreClasses.length)];

  return { mostSavedClass, leastSavedClass };
}

// B. Humans vs. Species
function calculateHumansVsSpecies(userDilemmas) {
  const humansCount = countAttributes(userDilemmas, decision => true, 'landUse');
  const score = humansCount.chosen / humansCount.total;
  return score;
}

// C. Effectiveness of Your Decisions
function calculateEffectiveness(userDecisions) {
  const { total: over1KCount, chosen: over1KChosenCount } = countAttributes(userDecisions, pairs => pairs.some(decision => decision.landUse?.effectiveness > 1000), 'landUse');
  const { total: under1KCount, chosen: under1KChosenCount } = countAttributes(userDecisions, pairs => pairs.some(decision => decision.landUse?.effectiveness <= 1000), 'landUse');
  const score = (over1KChosenCount / (over1KCount || 1)) - (under1KChosenCount / (under1KCount || 1));
  return score;
}

// D. Species Choices
// D.1. Charismatic Species
function calculateCharismaticSpecies(userDecisions) {
  const { total: highChCount, chosen: highChChosenCount } = countAttributes(userDecisions, pairs => pairs.some(decision => decision.species?.charisma === "High"));
  const { total: lowChCount, chosen: lowChChosenCount } = countAttributes(userDecisions, pairs => pairs.some(decision => decision.species?.charisma === 'Low'));
  console.log(highChCount, highChChosenCount, lowChCount, lowChChosenCount);
  const score = (highChChosenCount / (highChCount || 1)) - (lowChChosenCount / (lowChCount || 1));
  return score;
}

// D.2. Ecologically Important Species
function calculateEcologicallyImportantSpecies(userDecisions) {
  const { total: highEICount, chosen: highEIChosenCount } = countAttributes(userDecisions, pairs => pairs.some(decision => decision.species?.ecologicalImportance === 'High'));
  const { total: lowEICount, chosen: lowEIChosenCount } = countAttributes(userDecisions, pairs => pairs.some(decision => decision.species?.ecologicalImportance === 'Low'));

  const score = (highEIChosenCount / (highEICount || 1)) - (lowEIChosenCount / (lowEICount || 1));
  return score;
}

// E. Land Use Choices
// E.1. Economic Impact
function calculateEconomicImpact(userDecisions) {
  const { total: highEconCount, chosen: highEconChosenCount } = countAttributes(userDecisions, pairs => pairs.some(decision => decision.landUse?.economicImpact === 'High'), 'landUse');
  const { total: lowEconCount, chosen: lowEconChosenCount } = countAttributes(userDecisions, pairs => pairs.some(decision => decision.landUse?.economicImpact === 'Low'), 'landUse');

  const score = (highEconChosenCount / (highEconCount || 1)) - (lowEconChosenCount / (lowEconCount || 1));
  return score;
}

// E.2. Ecological Impact
function calculateEcologicalImpact(userDecisions) {
  const { total: highEcolCount, chosen: highEcolChosenCount } = countAttributes(userDecisions, pairs => pairs.some(decision => decision.landUse?.ecologicalImpact === 'High'), 'landUse');
  const { total: lowEcolCount, chosen: lowEcolChosenCount } = countAttributes(userDecisions, pairs => pairs.some(decision => decision.landUse?.ecologicalImpact === 'Low'), 'landUse');

  const score = (highEcolChosenCount / (highEcolCount || 1)) - (lowEcolChosenCount / (lowEcolCount || 1));
  return score;
}

// Main Calculation Function
export function generateResults(dilemmas) {
  let { mostSavedClass, leastSavedClass } = calculateSavedClasses(dilemmas);
  let humansVsSpeciesScore = calculateHumansVsSpecies(dilemmas);
  let effectivenessScore = calculateEffectiveness(dilemmas);
  let charismaticSpeciesScore = calculateCharismaticSpecies(dilemmas);
  let ecologicallyImportantSpeciesScore = calculateEcologicallyImportantSpecies(dilemmas);
  let economicImpactScore = calculateEconomicImpact(dilemmas);
  let ecologicalImpactScore = calculateEcologicalImpact(dilemmas);

  return {
      mostSavedClass,
      leastSavedClass,
      humansVsSpeciesScore,
      effectivenessScore,
      charismaticSpeciesScore,
      ecologicallyImportantSpeciesScore,
      economicImpactScore,
      ecologicalImpactScore
  };
}